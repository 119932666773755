import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Logo } from '../logo'

const SplashImg = styled(props => <Img {...props} />)`
  display: block;
  height: 100%;
  width: 100%;

  ${ props => props.theme.media.tablet`
    display: none;
  ` }
`

const SplashImgMobile = styled(props => <Img {...props} />)`
  display: none;
  height: 100%;
  width: 100%;

  ${ props => props.theme.media.tablet`
    display: block;
  ` }
`

const SplashImage = props => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(
          relativePath: { eq: "splash.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logoMobile: file(
          relativePath: { eq: "splash-mobile.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }`
    }
    render={ data => (
      <>
        <SplashImg
          fluid={data.logo.childImageSharp.fluid} />
        <SplashImgMobile
          fluid={data.logoMobile.childImageSharp.fluid} />
      </>
    )} />
)

const FullPage = styled.div`
  height: 100vh;
  width: 100%;
  min-height: 600px;
  overflow: hidden;
`

const ImgContainer = styled.div`
  height: 100%;
  width: 100%;
`

const TextSection = styled.div`
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LogoBlock = styled.div`
  padding: 0 10px 30px 10px;
  border-bottom: ${ props => props.theme.black } solid 1px;
  width: 300px;

  ${ props => props.theme.media.tablet`
    width: 200px;
  ` }
`

const TextBlock = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  letter-spacing: 2px;
  margin: auto;
  min-height: 400px;
  text-align: center;
  text-transform: uppercase;
  width: 80%;
  height: 60%;

  ${ props => props.theme.media.tablet`
    width: 100%;
    height: 100%;
  ` }
`

const Text = styled.h1`
  font-family: ${ props => props.theme.bodyFont };
  font-size: 1.2rem;
  font-weight: 400;
  padding: 40px;
  max-width: 500px;
  text-transform: uppercase;

  ${ props => props.theme.media.tablet`
    font-size: 0.9rem;
    padding: 20px 10px;
  ` }
`

const SplashBlock = props => (
  <FullPage>
    <ImgContainer>
      <SplashImage />
    </ImgContainer>
    <TextSection>
      <TextBlock>
        <LogoBlock>
          <Logo />
        </LogoBlock>
        <Text>
          Helping you create a beautiful home and wardrobe for every stage of
          motherhood.
        </Text>
      </TextBlock>
    </TextSection>
  </FullPage>
)

export {
  SplashBlock
}
