import React, { Component } from 'react'
import styled from 'styled-components'
import {
  StaticQuery,
  Link,
  graphql,
  navigate
} from 'gatsby'
import { Bars } from 'styled-icons/fa-solid'
import { motion } from 'framer-motion'

import {
  Instagram,
  Pinterest,
  Facebook,
  Tiktok
} from 'styled-icons/fa-brands'
import {
  Email
} from 'styled-icons/material'
import {
  INSTAGRAM_LINK,
  PINTEREST_LINK,
  // FACEBOOK_LINK,
  TIKTOK_LINK,
  EMAIL_LINK,
  SmartLink
} from '../utils'

import { LogoSmall } from '../components/logo-small'

const NavWrapper = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10000;
  height: ${ props => props.theme.navHeight };
`

const NavBar = styled.div`
  background-color: ${ props => props.theme.navColor };
  height: ${ props => props.theme.navHeight };
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin: 0;
  padding: 0 20px;
  opacity: 1;
`

const NavLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 60px;
  width: 200px;

  ${ props => props.theme.media.desktop`
    width: auto;
    justify-content: center;
  ` }
`

const NavCenter = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  /* justify-content: flex-start; */
  justify-content: center;

  max-width: 998px;
  padding: 10px 0;
  margin: 0;

  ${ props => props.theme.media.desktop`
    justify-content: center;
  ` }
`

const NavRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-width: 60px;
  width: 200px;
  ${ props => props.theme.media.desktop`
    width: auto;
  ` }
`

const InstagramIcon = styled(Instagram)`
  color: ${ props => props.theme.black };
  width: 20px;
  display: flex;
  align-items: center;
`

const PinterestIcon = styled(Pinterest)`
  color: ${ props => props.theme.black };
  width: 20px;
  display: flex;
  align-items: center;
`

const FacebookIcon = styled(Facebook)`
  color: ${ props => props.theme.black };
  width: 20px;
  display: flex;
  align-items: center;
`

const TiktokIcon = styled(Tiktok)`
  color: ${ props => props.theme.black };
  width: 20px;
  display: flex;
  align-items: center;
`

const EmailIcon = styled(Email)`
  color: ${ props => props.theme.black };
  width: 20px;
  display: flex;
  align-items: center;
`


const BarButton = styled.button`
  border: 0;
  background: transparent;
`

const BarsIcon = styled(Bars)`
  color: ${ props => props.theme.black };
  width: 20px;
`

const navQuery = graphql`
  query Category {
    allContentfulCategory(
      filter: {
        contentfulparent: { contentful_id: { eq: null }}
        active: { eq: true }
      }
      sort: {fields:[order], order:ASC}) {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`

const Nav = styled.div`
  display: flex;
  list-style: none;
  margin: 0;
  height: ${ props => props.theme.navHeight };
  font-size: 1.2rem;

  ${ props => props.theme.media.desktop`
    display: none;
  ` }
`

const NavFixed = styled.div`
  width: 100%;
  height: ${ props => props.theme.navHeight };
  z-index: 999;
  display: none;
  background-color: #fff;

  .bm-menu-wrap {
    width: 100% !important;
    max-width: 500px !important;
  }

  ${ props => props.theme.media.desktop`
    display: flex;
    align-items: center;
  ` }
`

const NavMobile = styled.div`
  display: none;
  padding: 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  text-transform: uppercase;
  background-color: ${ props => props.theme.navColor };
  height: ${ props => props.theme.navHeight };

  ${ props => props.theme.media.desktop`
    display: flex;
  ` }
`

const NavMobileMenu = styled.div`
  background-color: ${ props => props.theme.navColor };
  padding: 0;
  outline: none;
  width: 100%;
  height: 100%;
  margin-top: 0;
  z-index: 1010 !important;

  display: flex;
  flex-direction: column;
  height: 100%;

  a, button {
    font-size: 1.2rem;
    font-family: ${ props => props.theme.bodyFont } !important;
    text-transform: uppercase !important;
  }
`

const NavItem = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1em;

  color: rgba(${ props => props.theme.black }, 0.75);
  font-family: ${ props => props.theme.bodyFont };
  font-weight: 300;
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 2px;
`

const menuVariants = {
  show: {
    display: 'block',
    opacity: 1
  },
  hide: {
    opacity: 0,
    transitionEnd: {
      display: 'none'
    }
  }
}

const NavItemSocial = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px 0 0;
  padding: 0 10px 0 0;
`

const NavEmail = props => (
  <NavItemSocial>
    <NavExternalLink
      href={ EMAIL_LINK }
      target="_blank"
      content="nofollow"
      rel="noopener noreferrer">
      <EmailIcon />
    </NavExternalLink>
  </NavItemSocial>
)

const NavInstagram = props => (
  <NavItemSocial>
    <NavExternalLink
      href={ INSTAGRAM_LINK }
      target="_blank"
      content="nofollow"
      rel="noopener noreferrer">
      <InstagramIcon />
    </NavExternalLink>
  </NavItemSocial>
)

const NavPinterest = props => (
  <NavItemSocial>
    <NavExternalLink
      href={ PINTEREST_LINK }
      target="_blank"
      content="nofollow"
      rel="noopener noreferrer">
      <PinterestIcon />
    </NavExternalLink>
  </NavItemSocial>
)

const NavFacebook = props => (
  <NavItemSocial>
    <NavExternalLink
      href={ FACEBOOK_LINK }
      target="_blank"
      content="nofollow"
      rel="noopener noreferrer">
      <FacebookIcon />
    </NavExternalLink>
  </NavItemSocial>
)

const NavTiktok = props => (
  <NavItemSocial>
    <NavExternalLink
      href={ TIKTOK_LINK }
      target="_blank"
      content="nofollow"
      rel="noopener noreferrer">
      <TiktokIcon />
    </NavExternalLink>
  </NavItemSocial>
)

const NavLink = styled(Link)`
  color: ${ props => props.theme.black };
  text-decoration: none;
  position: relative;
  outline: 0;
  border: 0;
  border-bottom: transparent solid ${ props => props.theme.navBorderWidth };
  transition: border 0.25s;
  padding: 0;
  text-align: center;
  padding: 8px 0;
  margin-top: ${ props => props.theme.navBorderWidth };

  &:hover, &:active, &.active {
    border-bottom-color: ${ props => props.theme.black };
    transition: border 0.25s;
  }
`

const NavExternalLink = styled.a`
  color: ${ props => props.theme.black };
  text-decoration: none;
  position: relative;
  outline: 0;
  border: 0;
  border-bottom: transparent solid 2px;
  transition: border 0.25s;
  padding: 0;
  text-align: center;
  padding: 8px 0;
  margin-top: ${ props => props.theme.navBorderWidth };
`

const NavMobileLink = props => {
  const click = e => {
    props.closeMenu()
    navigate(props.to)
  }
  return (
    <_NavMobileLink onClick={click}>
      { props.children }
    </_NavMobileLink>
  )
}

const _NavMobileLink = styled.button`
  background-color: transparent;
  border-bottom: 0;
  border: 0;
  color: ${ props => props.theme.black };
  cursor: pointer;
  display: block;
  font-family: ${ props => props.theme.bodyFont };
  font-size: 1.2rem;
  font-weight: 300;
  margin: 0;
  outline: 0;
  padding: 1rem;
  position: relative;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase

  &:hover, &:active, &.active {
    font-weight: 600;
    background-color: ${ props => props.theme.primaryColor };
  }
`

const MobileSocial = styled.div`
  display: flex;
  flex-direction: row;
  border: ${ props => props.theme.black } solid 0px;
  border-bottom-width: 1px;
  margin: 0 1rem;
  max-width: 300px;
`

class Navigation extends Component {
  state = {
    menuOpen: false
  }

  toggle = () => {
    this.setState({
      menuOpen: !this.state.menuOpen
    })
  }

  close = () => {
    this.setState({
      menuOpen: false
    })
  }

  stateChanged = state => {
    this.setState({
      menuOpen: state.isOpen
    })
  }

  renderFull (pose, data) {
    return (
      <Nav>
        <NavBar pose={pose}>
          <NavLeft>
            <LogoSmall />
          </NavLeft>
          <NavCenter>
            {data.allContentfulCategory.edges.map(item => (
              <NavItem key={item.node.slug}>
                <SmartLink component={NavLink} to={`/${ item.node.slug }/`}>
                  {item.node.name}
                </SmartLink>
              </NavItem>
            ))}
            <NavItem key="press">
              <SmartLink component={NavLink} to="/press/">Press</SmartLink>
            </NavItem>
            <NavItem key="about">
              <SmartLink component={NavLink} to="/about/">About</SmartLink>
            </NavItem>
          </NavCenter>
          <NavRight>
            <NavEmail />
            <NavInstagram />
            <NavPinterest />
            { /*
            <NavFacebook />
            */ }
            <NavTiktok />
          </NavRight>
        </NavBar>
      </Nav>
    )
  }

  render () {
    const { path } = this.props
    const display = path === '/' ? 'none' : 'block'
    return (
      <StaticQuery
        query={navQuery}
        render={data => (
          <NavWrapper style={{ display: display }}>
            { this.renderFull('show', data) }
            <NavFixed>
              <NavMobile>
                <NavLeft>
                  <BarButton type="button" onClick={this.toggle}>
                    <BarsIcon />
                  </BarButton>
                </NavLeft>
                <NavCenter>
                  <LogoSmall />
                </NavCenter>
                <NavRight />
              </NavMobile>
            </NavFixed>
            <motion.div
              initial='hide'
              variants={menuVariants}
              animate={this.state.menuOpen ? 'show' : 'hide'}
              style={{
                position: 'fixed',
                top: 0,
                bottom: 0,
                width: '100%',
                zIndex: '10000'
              }}>
              <NavMobileMenu>
                <NavMobile>
                  <NavLeft>
                    <BarButton type="button" onClick={this.toggle}>
                      <BarsIcon />
                    </BarButton>
                  </NavLeft>
                  <NavCenter>
                    <LogoSmall />
                  </NavCenter>
                  <NavRight>
                  </NavRight>
                </NavMobile>
                {data.allContentfulCategory.edges.map(item => (
                  <SmartLink
                    key={item.node.slug}
                    component={NavMobileLink}
                    closeMenu={this.close}
                    to={`/${ item.node.slug }/`}>
                    {item.node.name}
                  </SmartLink>
                ))}
                <SmartLink
                  component={NavMobileLink}
                  closeMenu={this.close}
                  to="/press/">
                  Press
                </SmartLink>
                <SmartLink
                  component={NavMobileLink}
                  closeMenu={this.close}
                  to="/about/">
                  About
                </SmartLink>
                <MobileSocial>
                  <NavEmail />
                  <NavInstagram />
                  <NavPinterest />
                  { /*
                  <NavFacebook />
                  */ }
                  <NavTiktok />
                </MobileSocial>
                <SmartLink
                  key={'subscribe'}
                  component={NavMobileLink}
                  closeMenu={this.close}
                  to={`/subscribe/`}>
                  Subscribe
                </SmartLink>
              </NavMobileMenu>
            </motion.div>
          </NavWrapper>
        )}
      />
    )
  }
}

export {
  Navigation
}
