import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { Seo } from '../components/seo'

import {
  InstaWrapper,
  InstaText,
  InstaGallery,
  InstaPosts,
  InstaToggle
} from '../components/instagram'

class InstagramPosts extends React.Component {
  render () {
    const posts = get(this.props, 'data.allContentfulInstagramPost.edges', [])
    const title = 'Instagram'
    return (
      <div>
        <Seo title={title} location={this.props.location} />
        <InstaWrapper>
          {/*
          <InstaText>
            Shop my Instagram Feed! Click on an image to shop everything
            pictured!
          </InstaText>
          */}
          <InstaToggle />
        </InstaWrapper>
        <InstaWrapper>
          <InstaGallery>
            <InstaPosts posts={posts} />
          </InstaGallery>
        </InstaWrapper>
      </div>
    )
  }
}

export default InstagramPosts

export const pageQuery = graphql`
  query InstaGallery {
    allContentfulInstagramPost(
      sort: {fields:[publishedDate], order:DESC}
    ) {
      edges {
        node {
          id
          url
          items {
            __typename
            ... on ContentfulProduct {
             slug
            }
            ... on ContentfulCollection {
              slug
            }
          }
          image {
            fixed(width:400, height:400) {
              width
              height
              src
            }
          }
        }
      }
    }
  }
`
