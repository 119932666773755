import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import { BlockWrapper } from '../containers'

const CursiveBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -10px;
`

const Cursive = styled.div`
  font-family: ${ props => props.theme.cursiveFont };
  font-size: 5rem;
  margin: 0 20px;
`

const AboutPhoto = props => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(
          relativePath: { eq: "home-about.jpg" }) {
          childImageSharp {
            fixed(width: 350) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }`
    }
    render={ data => (
      <Img fixed={data.logo.childImageSharp.fixed} />
    )} />
)

const Container = styled(props => <BlockWrapper {...props} />)`
`

const AboutFlex = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${ props => props.theme.bodyFont };
  font-weight: ${ props => props.theme.bodyWeight };
  font-size: 1.2rem;

  ${ props => props.theme.media.tablet`
    flex-direction: column;
  ` }

  & > div {
    flex: 1 1 auto;
  }
`

const H2 = styled.h2`
  text-align: center;
  font-size: 1.5rem;
  font-family: ${ props => props.theme.headerFont };
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
`

const Hr = styled.div`
  width: 200px;
  border: ${ props => props.theme.black } solid 0;
  border-bottom-width: 1px;
  margin: 20px auto 40px auto;
`

const Text = styled.div`
  padding 0 20px 0 0;
`

const PhotoWrapper = styled.div`
  margin: 0;

  ${ props => props.theme.media.phone`
    margin: 0 auto;
  ` }
`

const AboutBlock = props => (
  <Container>
    <H2>
      Former event planner & preschool teacher sharing tablescape & home ideas
      while covering all things Motherhood
    </H2>
    <Hr />
    <AboutFlex>
      <Text>
        <p>
          Hi! I am Chrissy, mom of 3 and an expert Amazon shopper.
        </p>
        <p>
          Before becoming a mother, I worked in the events and wedding industry
          for several years, then switched my focus to pursue my love of
          teaching. With my experience in event planning and my education in
          early childhood development, I developed this blog to be a resource in
          providing practical and attainable ways to style your home and
          wardrobe through every stage of Motherhood.
        </p>

        <p>
          I also have a whole section on this website dedicated to Motherhood.
          There, I share what I have learned from my teaching experience and
          education in early childhood development.
        </p>

        <p>
          Thank you for being here! Please do not hesitate to reach out if you want to connect!
        </p>
        <CursiveBlock>
          <Cursive>Chrissy</Cursive>.
        </CursiveBlock>
      </Text>
      <PhotoWrapper>
        <AboutPhoto />
      </PhotoWrapper>
    </AboutFlex>
  </Container>
)

export {
  AboutBlock
}
