import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { LogoSmall } from './../logo-small'
import { InstagramImage } from './../comingSoon'
import { PageWrapper } from '../containers'
import InstagraPic from '../../images/instapic.jpg'
import { SubscribeModal } from './subscribe-modal'

const FooterContainer = styled.div`
  margin: 0;
  margin-top: 20px;
  padding: 0;
  border-top: #fafafa solid 1px;
`

const FooterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 50px;
  width: 100%;
`

const FooterCopy = styled(props => <FooterRow {...props} />)`
  background-color: rgba(${ props => props.theme.footerColor }, 0.75);
  font-family: ${ props => props.theme.bodyFont };
  padding: 20px 0;
`

const FooterNav = styled(props => <FooterRow {...props} />)`
  background-color: #fff;
  padding: 20px 0;
`

const FooterHello = styled(props => <FooterRow {...props} />)`
  background-color: ${ props => props.theme.footerColor };
  padding: 20px 0;
`

const FooterWrapper = styled(props => <PageWrapper {...props} />)`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${ props => props.theme.media.phone`
    flex-direction: column;
  ` }
`

const FooterLeft = styled.div`
  width: 30%;
  display: flex;
  padding-right: 20px;
  justify-content: flex-end;

  ${ props => props.theme.media.phone`
    justify-content: center;
    padding-right: 0;
  ` }
`

const FooterGrid = styled.div`
  width: 100%;
  justify-content: space-between;

  ${ props => props.theme.media.phone`
    justify-content: center;
    padding-left: 0;
  ` }

  display: flex;
  flex-direction: row;
  flex-flow: wrap;

  div {
    h2 {
      font-weight: 600;
      font-size: 0.8rem;
      text-transform: uppercase;
      margin-bottom: 10px;
      font-family: ${ props => props.theme.bodyFont };
      letter-spacing: 2px;
    }
    padding-right: 40px;
    padding-bottom: 40px;
    width: auto;

    ${ props => props.theme.media.phone`
      padding-right: 0;
      text-align: center;
      width: 100%;
    ` }

    a, button {
      color: rgba(${ props => props.theme.black }, 0.8);
      font-family: ${ props => props.theme.bodyFont };
      font-size: 0.8rem;
      background-color: transparent;
      letter-spacing: 1px;
      text-transform: uppercase;
      border: rgba(0, 0, 0, 0) solid 0;
      border-bottom-width: 1px;
      transition: all 0.25s;
      text-align: left;
      margin: 0;
      padding: 0;
      cursor: pointer;

      &:hover {
        border-bottom: ${ props => props.theme.black } solid 1px;
        transition: all 0.25s;
      }
    }
  }

  a {
    font-weight: 400;
    color: ${ props => props.theme.black };
    text-decoration: none;
  }
`

const FooterSalutation = styled(props => <FooterGrid {...props} />)`
  font-family: ${ props => props.theme.bodyFont };

  ${ props => props.theme.media.phone`
    text-align: center;
  ` }

`

const FooterLogo = styled.div`
 text-align: center;
 display: flex;
 flex-direction: row;
 align-items: center;
 width: 100%;
`

const FooterLogoBar = styled.div`
  height: 1px;
  border: ${ props => props.theme.black } solid 0;
  border-bottom-width: 1px;
  flex: 1 0 auto;
`

const Footer = props => {
  const [show, setShow] = useState(false)
  return (
    <FooterContainer>
      <SubscribeModal show={ show } setShow={ setShow } />
      <FooterWrapper>
        <FooterLogo>
          <FooterLogoBar style={{ marginRight: '30px' }}/>
          <LogoSmall />
          <FooterLogoBar style={{ marginLeft: '30px' }}/>
        </FooterLogo>
      </FooterWrapper>
      <FooterNav>
        <FooterWrapper>
          <FooterGrid>
            <div>
              <h2>fashion</h2>
              <Link to={'/fashion/outfits/'}>outfits by occasion</Link><br />
              <Link to={'/instagram/'}>shop my social</Link><br />
            </div>
            <div>
              <h2>decor</h2>
              <Link to={'/home-decor/'}>home decor</Link><br />
              <Link to={'/home-decor/holiday-and-events-decor/'}>
                holiday &amp; events decor
              </Link><br />
            </div>
            <div>
              <h2>motherhood</h2>
              <Link to={'/motherhood/maternity/'}>maternity fashion</Link><br />
              <Link to={'/motherhood/postpartum/'}>postpartum style</Link><br />
              <Link to={'/motherhood/preschool/'}>home preschool activties</Link><br />
            </div>
            <div>
              <h2>lifestyle</h2>
              <Link to={'/lifestyle/food-drinks/'}>food & drinks</Link><br />
            </div>
            <div>
              <h2>about</h2>
              <button onClick={() => { setShow(!show) }}>
                subscribe
              </button><br />
              <Link to={'/about'}>about</Link><br />
              <a href="mailto:chrissy@hortonlane.com">
                contact
              </a><br />
            </div>
          </FooterGrid>
        </FooterWrapper>
      </FooterNav>
      <FooterHello>
        <FooterWrapper>
          <FooterLeft>
            <InstagramImage dim={100} src={ InstagraPic } />
          </FooterLeft>
          <FooterSalutation>
            Hi! I am Chrissy and I created this website to make finding
            fashion, home and events decor items from Amazon easy. As an Amazon
            Associate I earn from qualifying purchases.
          </FooterSalutation>
        </FooterWrapper>
      </FooterHello>
      <FooterCopy>
        Copyright © 2021. All Rights Reserved.
      </FooterCopy>
    </FooterContainer>
  )
}

export {
  Footer
}
