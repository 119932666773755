import React from 'react'
import { Navigation } from '../components/navigation'
import {
  PageWrapper,
  ContentBlock
} from './containers'
import { Footer } from './footer/index'

import './mainContainer.module.css'

const GlobalWrapper = ({ children, path }) => (
  <div>
    <Navigation path={path} />
    <ContentBlock>
      { children }
    </ContentBlock>
    <Footer />
  </div>
)

const Template = ({ children }) => (
  <PageWrapper>{children}</PageWrapper>
)

export {
  GlobalWrapper,
  Template,
  PageWrapper
}
